<template>
  <div class="container prow-40">
    <h1 class="title pt-20 pb-10">关于幼享乐:</h1>
    <div class="txt f-c-c f-col">
      <span> 幼享乐是一款记录幼儿成长影像的分享平台， </span>
      <span> 致力于服务中国幼儿家长，并为家长提供交流与分享平台 </span>
      <span> 基围绕影像服务的社交应用 使用幼享乐，记录成长美好瞬间 </span>
      <span class="mt-30"> 微信公众号：幼享乐 </span>
      <span> 电子邮箱：youxiangle202163@163.com </span>
      <span> 官方网址： </span>
      <span class="mt-30 fs-22"> 感谢您对幼享乐的支持与理解。 </span>
      <span class="fs-22"> 广东柏斯融媒体科技有限公司 </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact'
};
</script>

<style lang="scss" scoped>
.title,
.txt {
  font-size: 0.28rem;
  color: #0a1738;
  line-height: 0.4rem;
  letter-spacing: 0.02rem;
}
.txt {
  font-size: 0.24rem;
}
.fs-22 {
  font-size: 0.22rem;
}
</style>
